export enum MessageType {
    USER = "USER",
    AGENT = "AGENT",
    CLOSED_CONNECTION = "CLOSED_CONNECTION",
    WELCOME = "WELCOME",
    UNREAD_MESSAGE_DIVIDER = "UNREAD_MESSAGE_DIVIDER",
    SELECT_TO_START_CHAT = "SELECT_TO_START_CHAT"
}
  
export const dividerMessageTypes: string[] = [MessageType.CLOSED_CONNECTION, MessageType.UNREAD_MESSAGE_DIVIDER, MessageType.SELECT_TO_START_CHAT]
  
export const chatPageSize = 10
  
export const POLLING_INTERVAL = 3000;

export enum ChatbotActionKind {
    SET_IS_CHATBOT_RESPONSE_LOADING = 'SET_IS_CHATBOT_RESPONSE_LOADING',
    SET_IS_CHATBOT_CHAT = 'SET_IS_CHATBOT_CHAT',
    SET_SHOULD_HANDOVER_TO_AGENT = 'SET_SHOULD_HANDOVER_TO_AGENT',
    SET_IS_AGENT_HANDOVER_LOADING = 'SET_IS_AGENT_HANDOVER_LOADING',
    ADD_SUBMITTED_FEEDBACK_MESSAGES = 'ADD_SUBMITTED_FEEDBACK_MESSAGES',
}

export enum FeedbackState {
    ENABLED = "ENABLED",
    DISABLED = "DISABLED",
    SUBMITTED = "SUBMITTED"
}

export const chatIssueTypesWithoutChatbot = {
    "retail": [
        "helpcentre_chat_page_retail_chat_issue_1",
        "helpcentre_chat_page_retail_chat_issue_2",
        "helpcentre_chat_page_retail_chat_issue_3",
        "helpcentre_chat_page_retail_chat_issue_4",
        "helpcentre_chat_page_retail_chat_issue_5",
    ],
    "business": [
        "helpcentre_chat_page_msme_chat_issue_1",
        "helpcentre_chat_page_msme_chat_issue_2",
        "helpcentre_chat_page_msme_chat_issue_3",
        "helpcentre_chat_page_msme_chat_issue_4",
        "helpcentre_chat_page_msme_chat_issue_5",
    ]
}

export const chatIssueTypesWithChatbot = {
    "retail": [
        "helpcentre_chat_page_retail_chat_issue_1_with_chatbot",
        "helpcentre_chat_page_retail_chat_issue_2_with_chatbot",
        "helpcentre_chat_page_retail_chat_issue_3_with_chatbot",
        "helpcentre_chat_page_retail_chat_issue_4_with_chatbot",
        "helpcentre_chat_page_retail_chat_issue_5_with_chatbot",
    ],
    "business": [
        "helpcentre_chat_page_msme_chat_issue_1_with_chatbot",
        "helpcentre_chat_page_msme_chat_issue_2_with_chatbot",
        "helpcentre_chat_page_msme_chat_issue_3_with_chatbot",
        "helpcentre_chat_page_msme_chat_issue_4_with_chatbot",
        "helpcentre_chat_page_msme_chat_issue_5_with_chatbot",
    ]
}

