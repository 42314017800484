import { Box, Button, InputBase, Paper, Typography } from "@mui/material"
import ListItem from "../ListItem/ListItem"
import RadioActive from '../../assets/radio-active.svg'
import RadioInactive from '../../assets/radio-inactive.svg'
import { useMemo, useState } from "react"
import theme from "../../theme"
import wordsCount from "../../utils/words"
import i18n from "../../i18n/config"

type MessageRatingFeedbackProps = {
  onSubmit: (feedback: string) => void
}

const options = [
  "helpcentre_chat_page_chatbot_feedback_option_1",
  "helpcentre_chat_page_chatbot_feedback_option_2",
  "helpcentre_chat_page_chatbot_feedback_option_3",
  "helpcentre_chat_page_chatbot_feedback_option_4",
  "helpcentre_chat_page_chatbot_feedback_option_5",
]

const OTHERS = "helpcentre_chat_page_chatbot_feedback_option_5"

const MessageRatingFeedback = (props: MessageRatingFeedbackProps) => {
  const [currentSelection, setCurrentSelection] = useState("")
  const [customFeedback, setCustomFeedback] = useState("")


  
  const submitFeedback = () => {
    const feedback = currentSelection === OTHERS ? customFeedback : i18n.t(currentSelection)
    props.onSubmit(feedback)
  }

  const customFeedbackWordsCount = useMemo(() => {
    return wordsCount(customFeedback)
  }, [customFeedback])

  return (
    <Box sx={{ 
        backgroundColor: "bg-secondary.main", 
        borderRadius: 3,
        p: 5,
        mt: -2,
        mb: 5
      }}
    >
        <ListItem
          sx={{
            borderBottomLeftRadius: "0 !important",
            borderBottomRightRadius: "0 !important",
            px: 0,
          }}
          primaryText={
            <Typography variant="callout-03" sx={{ color: "text-primary.main" }}>{i18n.t("helpcentre_chat_page_chatbot_feedback_title")}</Typography>
          }
        />
        <Box>
          {
            options?.map((option, index) => {
              return (
                <ListItem
                  key={index}
                  id={option}
                  onClick={() => {
                      setCurrentSelection(option)
                  }}
                  sx={{
                      whiteSpace: "pre-wrap",
                      color: 'text-primary.main',
                      backgroundColor: 'bg-secondary.main',
                      px: 0,
                  }}
                  primaryText={
                      <Typography variant="body-03" sx={{ color: "text-primary.main" }}>{i18n.t(option)}</Typography>
                  }
                  secondaryIcon={option === currentSelection ? <img src={RadioActive} alt="" /> : <img src={RadioInactive} alt="" />}
                />
              )
            })
            }
        </Box>
        {
        currentSelection === OTHERS && 
        <Box sx={{
          display: "flex",
          flexDirection: "column",
          gap: 3,
          mt: 2,
          mb: 5,
        }}>
          <Paper
            component="form"
            classes={{ root: 'border-radius-large' }}
            sx={{
              px: 5,
              py: 2,
              display: 'flex',
              alignItems: 'center',
              backgroundColor: 'bg-primary.main',
              boxShadow: 'none',
              '&:Mui-disabled': {
                opacity: 0.35
              },
            }}
          >
            <Typography component="div" variant="body-02" width='100%'>
              <InputBase
                multiline
                fullWidth
                sx={{
                  flex: 1,
                  color: 'text-primary.main',
                  'textarea::placeholder': { color: 'text-secondary.main' },
                  caretColor: theme.palette["text-primary"].main,
                  ...theme.typography["body-02"]
                }}
                placeholder={i18n.t("helpcentre_chat_page_chatbot_feedback_input_placeholder")}
                value={customFeedback}
                maxRows="4"
                onChange={(e) => setCustomFeedback(e.target.value)}
              />
            </Typography>
          </Paper>
          <Typography variant="metadata-01" textAlign="end" 
          sx={{ 
            color: customFeedbackWordsCount > 20 ? theme.palette['status-error'].main : theme.palette['text-placeholder'].main }}>
              {customFeedbackWordsCount}/20 words
          </Typography>
        </Box>
      
        }
        <Button variant="outlined" color="btn-secondary" disabled={currentSelection === "" || (currentSelection === OTHERS && customFeedbackWordsCount > 20)} fullWidth 
          sx={{ 
            p: 3, 
            mt:3,
            ':disabled': {
              filter: "brightness(50%)",
            }
          }}
          onClick={submitFeedback}
        >
          <Typography color="text-primary.main" variant="metadata-01-semibold">Submit</Typography>
        </Button>
    </Box>
  )
}

export default MessageRatingFeedback